import React, { useEffect, useState } from "react";
import _ from "lodash";
import Cookies from "js-cookie";
import { useProjectsList, useTeamList } from "../data/data";
import { BASE_URL } from "../api/api";
import { Button } from "../components/Button/button";
import { useMemberDetails } from "../context/AuthContext";



type createProps = {
  closeDialog: any;
};

const AddTask = ({ closeDialog }: createProps) => {
  const [taskName, setTaskName] = useState("");
  const [description, setDescription] = useState("");
  const user_id: any = Cookies.get("id");
  const user_type = Cookies.get("user_type");
  const [IsDisabled, setIsDisabled] = useState(true)
  const [adminOrgDetails, setAdminOrgDetails] = useState<any>([])
  const [isbtnLoad, setIsbtnLoad] = useState(false);
  const { memberDetails: orgDetails, error: orgError } = useMemberDetails();


  const [memberDetails, setMemberDetails] = useState<any>({});
  const [error, setError] = useState<any>(null);

  const [errorValidation, setErrorValidation] = useState(null);
  const ORG_ID =
  user_type === "admin"
    ? orgDetails && orgDetails[0]?.id
    : orgDetails && orgDetails[0]?.org_id;

    const { data: projectsList, isLoading, isFetching, refetch } = useProjectsList(
      "getProjects",
      ORG_ID
    );
  
    const { data: teamList, isLoading: teamListLoad, isFetching: tamListFetch, refetch: teamListRefetch } = useTeamList(
      "getMembersList",
      ORG_ID
    );

  let filtered_EngineerManager = _.filter(teamList,
    { 'designation': 'Engineer Manager' }
);
let filtered_QaManager = _.filter(teamList,
  { 'designation': 'QA Manager' }
);
 
// Printing the output 
console.log(filtered_QaManager);
  const [task, setTask] = useState({
    project: "",
    issueType: "story",
    priority: "major",
    dueDate: "",
    assignee: "",
    engineeringManager: "",
    fixVersion: "",
    sprint: "",
    qaOwner: "",
    reporter: "",
    org_id: "",
  });

  console.log("projectsList", projectsList)
  useEffect(() => {
    // Check if specific properties in the task object are not empty
    const { project, issueType, priority } = task;
    const areSpecificFieldsNotEmpty = project !== "" && issueType !== "" && priority !== "";

    // Update the IsDisabled state based on the check
    setIsDisabled(!areSpecificFieldsNotEmpty);
}, [task]); // Run this effect whenever the task object changes
 
  useEffect(() => {
    const fetchMemberDetails = async () => {
      const action  = "getOrgId"
      try {
        const response = await fetch(`${BASE_URL}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ id: user_id, user_type: user_type, action }),
        });

        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        const data = await response.json();
        setMemberDetails(data);
      } catch (error: any) {
        setError(`Error fetching member details, ${error.message}`);
      }
    };

    if (user_id && (user_type === 'admin' || user_type === 'member')) {
      fetchMemberDetails();
    } else {
      setError('Invalid user ID or user type');
    }
  }, [user_id, user_type]);

  console.log("memberDetails", user_type === "admin" ? memberDetails[0]?.id : memberDetails[0]?.org_id )
  const orgID = user_type === "admin" ? memberDetails[0]?.id : memberDetails[0]?.org_id
  const handleAddTask = async (e: any) => {
    e.preventDefault();
    setIsbtnLoad(true);
    try {
      const response = await fetch("https://makeithost.com/api/api.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "addTask",
          task_name: taskName,
          description: description,
          project: task.project,
          issueType: task.issueType,
          priority: task.priority,
          dueDate: task.dueDate,
          assignee: task.assignee,
          engineeringManager: task.engineeringManager,
          fixVersion: task.fixVersion,
          sprint: task.sprint,
          qaOwner: task.qaOwner,
          reporter: task.reporter,
          status: "open",
          org_id: orgID,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log("result", result);
      setErrorValidation(result.result);

      // You can add additional logic here, such as redirecting or updating the UI.
    } catch (error: any) {
      console.error("Error:", error.message);
    }
  };

  function handleClose() {
    closeDialog(false);
    setErrorValidation(null);
  }

  

  return (
    <div
      style={{ backgroundColor: "#00000073" }}
      className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-screen h-screen"
    >
      <div className="relative p-4 w-8/12 max-h-full">
        <div className="relative bg-white rounded-lg shadow p-4">
          <div className="flex items-center justify-between border-b rounded-t dark:border-gray-100">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-gray leading-10">
              Create Task
            </h3>
          </div>

          <div className="flex">
            <label
              htmlFor="email"
              className="block text-xs text-gray-900 w-2/12 text-end leading-8 pe-2"
            ></label>
            <div className="w-full px-6">
              {errorValidation !== null ? (
                _.includes(errorValidation, "Error"  ) ||  _.includes(errorValidation, "Invalid" ) ? (
                  <small className="text-rose-700 px-4">{errorValidation} </small>
                ) : (
                  <small className="text-green-700 px-4">{errorValidation} </small>
                )
              ) : null}
            </div>
          </div>
          <form className="pt-4 w-full">
            <div className="px-6">
              <div className="flex my-2">
                <label
                  htmlFor="email"
                  className="block text-xs text-gray-900 w-2/12 text-end leading-8 pe-2"
                >
                  Project<small className="text-rose-400 mb-2">*</small>
                </label>

                <div className="mt-0 w-4/12">
                  <select
                    value={task.project}
                    onChange={(e) =>
                      setTask({ ...task, project: e.target.value })
                    }
                    className="px-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xs sm:leading-6"
                  >
                    <option value=''>Select</option>
                    {
                      projectsList?.map((e:any) =>{
                        return  <option value={e?.id}>{e?.name}</option>
                      })
                    }
                  </select>
                  {!projectsList ? <span className="text-rose-500 capitalize text-xs">Please create a project before creating a task.</span> : null}
                </div>
              </div>
              <div className="flex my-2">
                <label
                  htmlFor="email"
                  className="block text-xs text-gray-900 w-2/12 text-end leading-8 pe-2"
                >
                  Issue Type<small className="text-rose-400 mb-2">*</small>
                </label>

                <div className="mt-0 w-4/12">
                  <select
                    value={task.issueType}
                    onChange={(e) =>
                      setTask({ ...task, issueType: e.target.value })
                    }
                    className="px-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xs sm:leading-6"
                  >
                    <option value="Story">Story</option>
                    <option value="Epic">Epic</option>
                    <option value="Bug">Bug</option>
                    <option value="Task">Task</option>
                    <option value="CustomerEscalation">Customer Escalation</option>
                  </select>
                </div>
              </div>
              <div className="flex my-2">
                <label
                  htmlFor="email"
                  className="block text-xs text-gray-900 w-2/12 text-end leading-8 pe-2"
                >
                  Summary<small className="text-rose-400 mb-2">*</small>
                </label>
                <div className="mt-0 w-9/12">
                  <input
                    type="text"
                    value={taskName}
                    onChange={(e) => setTaskName(e.target.value)}
                    className="px-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xs sm:leading-6"
                  />
                </div>
              </div>
              <div className="flex my-2">
                <label
                  htmlFor="email"
                  className="block text-xs text-gray-900 w-2/12 text-end leading-8 pe-2"
                >
                  Priority<small className="text-rose-400 mb-2">*</small>
                </label>

                <div className="mt-0 w-4/12">
                  <select
                    value={task.priority}
                    onChange={(e) =>
                      setTask({ ...task, priority: e.target.value })
                    }
                    className="px-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xs sm:leading-6"
                  >
                    <option value="major">Major</option>
                    <option value="blocker">Blocker</option>
                    <option value="bug">Bug</option>
                    <option value="minor">Minor</option>
                  </select>
                </div>
              </div>
              <div className="flex my-2">
                <label
                  htmlFor="email"
                  className="block text-xs text-gray-900 w-2/12 text-end leading-8 pe-2"
                >
                  Due Date
                </label>

                <div className=" w-4/12">
                  <input
                    type="date"
                    value={task.dueDate}
                    onChange={(e) =>
                      setTask({ ...task, dueDate: e.target.value })
                    }
                    className="px-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xs sm:leading-6"
                  />
                </div>
              </div>
              <div className="flex my-2">
                <label
                  htmlFor="email"
                  className="block text-xs text-gray-900 w-2/12 text-end leading-8 pe-2"
                >
                  Assignee<small className="text-rose-400 mb-2">*</small>
                </label>

                <div className="mt-0 w-4/12">
                  <select
                    value={task.assignee}
                    onChange={(e) =>
                      setTask({ ...task, assignee: e.target.value })
                    }
                    className="px-4 block  w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xs sm:leading-6"
                  >
                    <option value="">Select</option>
                    {
                      teamList?.map((e: any  )=>{
                        return <option value={e.name}>{e.name}</option>
                      })
                    }
                  </select>
                </div>
              </div>
              <div className="flex my-2">
                <label
                  htmlFor="email"
                  className="block text-xs text-gray-900 w-2/12 text-end leading-8 pe-2"
                >
                  Engineering Manager<small className="text-rose-400 mb-2">*</small>
                </label>

                <div className="mt-0 w-4/12">
                  <select
                    value={task.engineeringManager}
                    onChange={(e) =>
                      setTask({ ...task, engineeringManager: e.target.value })
                    }
                    className="px-4 block  w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xs sm:leading-6"
                  >
                    <option value="">Select</option>
                    {
                      filtered_EngineerManager?.map((e: any  )=>{
                        return <option value={e.name}>{e.name}</option>
                      })
                    }
                  </select>
                </div>
              </div>
              
              <div className="flex my-2">
                <label
                  htmlFor="email"
                  className="block text-xs text-gray-900 w-2/12 text-end leading-8 pe-2"
                >
                  Fix Version
                </label>

                <div className="mt-0 w-4/12">
                  <select
                    value={task.fixVersion}
                    onChange={(e) =>
                      setTask({ ...task, fixVersion: e.target.value })
                    }
                    className="px-4 block  w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xs sm:leading-6"
                  >
                    <option value="story">fixVersion 1</option>
                    <option value="epic">fixVersion 2</option>
                    <option value="bug">fixVersion 3</option>
                    <option value="task">fixVersion 4</option>
                    <option value="CustomerEscalation">fixVersion 5</option>
                  </select>
                </div>
              </div>
              <div className="flex my-2">
                <label
                  htmlFor="email"
                  className="block text-xs text-gray-900 w-2/12 text-end leading-8 pe-2"
                >
                  Sprint
                </label>

                <div className="mt-0 w-4/12">
                  <select
                    value={task.sprint}
                    onChange={(e) =>
                      setTask({ ...task, sprint: e.target.value })
                    }
                    className="px-4 block w-4/12 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xs sm:leading-6"
                  >
                    <option value="story">sprint 1</option>
                    <option value="epic">sprint 2</option>
                    <option value="bug">sprint 3</option>
                    <option value="task">sprint 4</option>
                    <option value="CustomerEscalation">sprint 5</option>
                  </select>
                </div>
              </div>
              <div className="flex my-2">
                <label
                  htmlFor="email"
                  className="block text-xs text-gray-900 w-2/12 text-end leading-8 pe-2"
                >
                  QA Owner
                </label>

                <div className="mt-0 w-4/12">
                  <select
                    value={task.qaOwner}
                    onChange={(e) =>
                      setTask({ ...task, qaOwner: e.target.value })
                    }
                    className="px-4 block w-4/12 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xs sm:leading-6"
                  >
                    <option value="">Select</option>
                    {
                      filtered_QaManager?.map((e:any) =>{
                        return <option value={e.name}>{e.name}</option>
                      })
                    }
                  </select>
                </div>
              </div>
             
              <div className="flex my-2">
                <label
                  htmlFor="email"
                  className="block text-xs text-gray-900 w-2/12 text-end pt-4 pe-2"
                >
                  Description
                </label>

                <div className="w-9/12">
                  <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="px-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xs sm:leading-6"
                  />
                </div>
              </div>
            </div>
            <footer className="border-t mt-9 pt-3 flex justify-end dark:border-gray-100 px-6">
              <button onClick={handleClose} className="mx-4 text-sky-700">
                Cancel
              </button>
              <Button 
                children={"Add Task"} 
                type={'button'} 
                className="items-center flex justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-xs font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={handleAddTask}
                disabled={IsDisabled}
               />
            </footer>
          </form>
        </div>
      </div>
    </div>
  );
};

export { AddTask };
