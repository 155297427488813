import React, { useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

//import { useCookies } from "react-cookie";

const TopNav = (onToggle: any) => {
  const [toggleArrow, setToggleArrow] = useState(true);
  const navigate = useNavigate();
  function onClick(e: any) {
    Cookies.remove("id", { path: "/" });
    navigate("/login");
  }
  console.log("Cookies", Cookies);
  function handleUserMenu() {
    setToggleArrow((current) => !current);
  }
  console.log(toggleArrow);
  return (
    <aside
      className="flex justify-between fixed  ps-40 top-0 left-0  w-screen h-8 transition-transform -translate-x-full sm:translate-x-0"
      aria-label="Sidebar"
    >
      <div></div>
      <div>
        
      </div>
      <div className="flex w-20 justify-end">
        <div>
          <button className="inline-block relative mt-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-gray-700"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
              />
            </svg>
            <span className="animate-ping absolute top-1 right-0.5 block h-1 w-1 rounded-full ring-2 ring-green-400 bg-green-600"></span>
          </button>
        </div>
        <div
          className="relative userMenu my-2 mx-4 flex items-center cursor-pointer"
          onClick={handleUserMenu}
        >
          <img
            className="w-6 h-6 rounded-full"
            src="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
            alt=""
          />
          <span className="top-0 left-4 absolute  w-2.5 h-2.5 bg-green-400 border-2 border-white dark:border-gray-800 rounded-full"></span>

          {toggleArrow ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-3 h-3 mt-1 ms-1"
            >
              <path
                fillRule="evenodd"
                d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-3 h-3 mt-1 ms-1"
            >
              <path
                fillRule="evenodd"
                d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </div>
      </div>
      {!toggleArrow ? (
        <nav className="absolute right-3 leading-7 top-10 shadow-gray-500 text-xs bg-white transition-transform z-40">
          <ul>
            <li className="hover:bg-gray-100 px-4 cursor-pointer">Profile</li>
            <li
              className="hover:bg-gray-100 px-4 cursor-pointer"
              onClick={onClick}
            >
              Sign Out
            </li>
          </ul>
        </nav>
      ) : null}
    </aside>
  );
};

export { TopNav };
