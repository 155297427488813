import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles//ag-grid.css";
import "ag-grid-community/styles//ag-theme-alpine.min.css";
import Cookies from "js-cookie";
import { OnBoarding } from "../modules/OnBoarding";
import { fetchTasks } from "../api/api.js";
import { getProjects, getData } from "../api/api.js";
import { Button } from "../components/Button/button.tsx";
import { Link, useNavigate } from "react-router-dom";
import { useMemberDetails } from "../context/AuthContext";

const DashBoard = () => {
  const [tasks, setTasks] = useState([]);
  const [onboardDetails, setOnboardDetails] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [orgDetails, setOrgDetails] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(true);
  const user_id = Cookies.get("id");
  const user_type = Cookies.get("user_type");
  const navigate = useNavigate();
  const [redirected, setRedirected] = useState(false); // New state
  const { memberDetails, error } = useMemberDetails();

  console.log(memberDetails);
  const orgID =
    user_type === "admin"
      ? memberDetails && memberDetails[0]?.id
      : memberDetails && memberDetails[0]?.org_id;
  async function fetchTasksList() {
    try {
      const action = "getTasks";
      const Response = await fetchTasks(action, orgID);
      setTasks(Response);
    } catch (error) {
      console.error("Error during login process:", error.message);
    }
  }

  useEffect(() => {
    if (orgID) {
      navigate("/portal/dashboard");
    } else {
      navigate("/portal/onboarding");
    }
  }, [orgID]);

  console.log("orgID dashboard", orgID);

  async function getProjectsList() {
    try {
      const action = "getProjects";
      const orgID =
        user_type === "admin"
          ? memberDetails && memberDetails[0]?.id
          : memberDetails && memberDetails[0]?.org_id;
      const data = await getProjects(action, orgID);
      setOnboardDetails(data);
      console.log("Projects", orgID, data, memberDetails);
    } catch (error) {
      console.error("Error during login process:", error.message);
    }
  }

  async function getTeamList() {
    try {
      const action = "getMembersList";
      const orgID =
        user_type === "admin"
          ? memberDetails && memberDetails[0]?.id
          : memberDetails && memberDetails[0]?.org_id;
      const data = await getProjects(action, orgID);
      setTeamList(data);
      console.log("dfdsf", data);
    } catch (error) {
      console.error("Error during login process:", error.message);
    }
  }
  async function getLoginData() {
    try {
      const action = "getLoginDetails";
      const data = await getData(action, user_id);
      setUserDetails(data);
    } catch (error) {
      console.error("Error during login process:", error.message);
    }
  }

  useEffect(() => {
    getProjectsList();
    fetchTasksList();
    getLoginData();
    getTeamList();
  }, [memberDetails]);

  const columnDefs = [
    {
      headerName: "Key",
      field: "task_id",
      width: "100px",
      resizable: false,
      cellRenderer: (p) => {
        let statusColor;
        let icon;

        switch (p.data.issueType.toLowerCase()) {
          case "epic":
            statusColor = "#3498db"; // Light Blue
            icon = (
              <i
                class="fas fa-rocket text-xs text-white"
                style={{ fontSize: "9px" }}
              ></i>
            );
            break;
          case "story":
            statusColor = "#e67e22"; 
            icon = (
              <i
                class="fas fa-book-open text-white"
                style={{ fontSize: "9px" }}
              ></i>
            ); 
            break;
          case "bug":
            statusColor = "#9b59b6"; 
            icon = (
              <i
                class="fas fa-bug text-white"
                style={{ fontSize: "9px" }}
              ></i>
            ); 
            break;
          case "task":
            statusColor = "#2ecc71";
            icon = (
              <i
                class="fas fa-bug text-white"
                style={{ fontSize: "9px" }}
              ></i>
            ); 
            break;
          case "customerescalation":
            statusColor = "#34495e";
            icon = (
              <i
                class="fas fa-exclamation-triangle text-white"
                style={{ fontSize: "9px" }}
              ></i>
            ); 
            break;
          default:
            statusColor = ""; // Default color, you can set it to a default color if needed
        }
        console.log(p.data.issueType);
        return (
          <div className="flex">
            <span
              className="flex  rounded w-4 h-4"
              style={{
                backgroundColor: statusColor,
                border: `1px solid ${statusColor}`,
                padding: "3px 2px",
                margin: "8px 0px ",
              }}
            >
              {icon}
            </span>
            <Link
              to={`/portal/taskDetails/${p?.data?.task_id}`}
              className="mx-1 font-medium text-sky-500 dark:text-indigo-500"
              target="_blank"
              rel={p?.data?.task_name}
            >
              {p.data.task_id}
            </Link>
          </div>
        );
      },
    },
    {
      headerName: "Summary",
      field: "task_name",
      flex: 1,
      cellRenderer: (p) => {
        return (
          <Link
          to={`/portal/taskDetails/${p?.data?.task_id}`}
          className="mx-1 font-medium text-sky-500 dark:text-indigo-500"
          //target="_blank"
          rel={p?.data?.task_name}
          >
            {p?.data?.task_name}
          </Link>
        );
      },
    },
    {
      headerName: "Assignee",
      field: "assignee",
      width: "150px",
      resizable: true,
      cellRenderer: (p) => {
        return <span className="capitalize">{p?.data?.assignee}</span>;
      },
    },
    {
      headerName: "Engineering Manager",
      field: "engineeringManager",
      width: "150px",
      resizable: true,
    },
    {
      headerName: "Fix Version",
      field: "fixVersion",
      width: "100px",
      resizable: false,
    },
    { headerName: "Sprint", field: "sprint", width: "100px", resizable: false },
    {
      headerName: "Status",
      field: "status",
      width: "100px",
      resizable: false,
      cellRenderer: (p) => {
        let statusColor;

        switch (p.data.status.toLowerCase()) {
          case "open":
            statusColor = "#3498db"; // Light Blue
            break;
          case "inprogress":
            statusColor = "#e67e22"; // Orange
            break;
          case "codereview":
            statusColor = "#9b59b6"; // Purple
            break;
          case "codecomplete":
            statusColor = "#2ecc71"; // Green
            break;
          case "resolve":
            statusColor = "#34495e"; // Dark Gray
            break;
          default:
            statusColor = ""; // Default color, you can set it to a default color if needed
        }

        return (
          <span
            className="px-4 py-1 rounded"
            style={{ color: statusColor, border: `1px solid ${statusColor}` }}
          >
            {p.data.status}
          </span>
        );
      },
    },

    // {
    //   headerName: "Actions",
    //   field: "",
    //   flex: 1,
    //   cellRenderer: (p) => {
    //     return (
    //       <>
    //         <i className="fa-regular fa-pen-to-square"></i>
    //         <i className="fa-solid fa-trash ms-2"></i>
    //       </>
    //     );
    //   },
    // },
  ];
  function handleAdd() {
    setIsDialogOpen(true);
  }

  const orgTitle = memberDetails?.map((e) => {
    return e.name;
  });
  console.log("teamList", teamList);
  return (
    <div>
      {user_type === "admin" && memberDetails?.length > 0 ? (
        <>
          <div>
            <div className="flex justify-between my-3">
              <h2 className="text-lg capitalize">
                <span className="font-bold text-xl">
                  Welcome to{" "}
                  {user_type === "member" ? userDetails[0]?.name : orgTitle}
                </span>
              </h2>

              <Button
                type={"submit"}
                className={
                  "items-center flex justify-center rounded-md bg-indigo-600 px-3   font-semibold  text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                }
                onClick={handleAdd}
                children={"Add"}
              />
            </div>
            <div className="row w-full my-2 inline-grid grid-cols-4 gap-4">
              <div className="shadow-sm rounded-md bg-white p-4">
                <h2 className="text-sm font-semibold flex justify-between align-center">
                  Tasks <i className="fa-solid fa-check-double mx-2"></i>
                </h2>
                <div className="text-4xl my-4 text-indigo-500 font-bold">
                  {tasks ? tasks?.length : "--"}
                </div>
                <div className="text-xs capitalize">
                  Total Tasks from all the projects
                </div>
              </div>
              <div className="shadow-sm rounded-md bg-white p-4">
                <h2 className="text-sm font-semibold flex justify-between align-center">
                  Team Members <i className="fa-solid fa-users mx-2"></i>
                </h2>
                <div className="text-4xl my-4 text-indigo-800 font-bold">
                  {teamList ? teamList?.length : "--"}
                </div>
                <div className="text-xs capitalize">
                  Total Team Members under{" "}
                  <span className="text-indigo-800 font-bold">{orgTitle}</span>
                </div>
              </div>
              <div className="shadow-sm rounded-md bg-white p-4">
                <h2 className="text-sm font-semibold flex justify-between align-center">
                  Projects <i className="fa-solid fa-list mx-2"></i>
                </h2>
                <div className="text-4xl my-4 text-indigo-800 font-bold">
                  {onboardDetails ? onboardDetails?.length + "/3" : "--"}
                </div>
                <div className="text-xs capitalize">
                  Upgrade to
                  <span className="text-indigo-800 font-bold"> Pro plan</span>
                </div>
                {/* <div className="text-xs">
                You've hit the maximum limit of 3 tasks. Upgrade to our  
                  <span className="text-indigo-800 font-bold"> Pro plan for more.</span>
                </div> */}
              </div>
            </div>
            <div className="shadow-sm rounded-md bg-white p-4">
              <div
                className="ag-theme-alpine "
                style={{
                  height: 500,
                  zIndex: 0,
                  fontFamily: "ui-sans-serif, system-ui, sans-serif",
                  fontSize: "12px",
                }}
              >
                <AgGridReact
                  columnDefs={columnDefs}
                  rowData={tasks || []}
                  rowHeight={35}
                  pagination={true}
                  paginationPageSize={20}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="flex space-x-2 justify-center items-center bg-white  dark:bg-indigo-800 h-full dark:invert">
          <div
            className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-indigo-500 border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status"
          >
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Loading...
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export { DashBoard };
