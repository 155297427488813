import React, { useState, useEffect } from 'react';

type Option = {
  value: string;
  label: string;
};

type DropdownProps = {
  options: Option[];
  onSelect: (selectedOption: Option | null) => void;
  defaultValue?: string;
};

const DropdownSearch: React.FC<DropdownProps> = ({ options, onSelect, defaultValue }) => {
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredOptions, setFilteredOptions] = useState<Option[]>(options);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    // Filter options based on the search term
    const filtered = options.filter(option =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredOptions(filtered);
  }, [searchTerm, options]);

  useEffect(() => {
    // Set default value when defaultValue prop changes
    const defaultOption = options.find(option => option.value === defaultValue);
    if (defaultOption) {
      setSelectedOption(defaultOption);
    }
  }, [defaultValue, options]);

  const handleSelect = (option: Option) => {
    setSelectedOption(option);
    onSelect(option);
    setSearchTerm(''); // Clear search term after selection
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative">
      <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        onFocus={toggleDropdown}
        onBlur={() => setTimeout(() => setIsOpen(false), 200)}
        className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
      />

      {isOpen && (
        <div className="absolute z-10 mt-1 w-full rounded-md bg-white shadow-lg">
          <ul className="py-1">
            {filteredOptions.map(option => (
              <li
                key={option.value}
                onClick={() => handleSelect(option)}
                className={`cursor-pointer px-4 py-2 ${
                  selectedOption?.value === option.value ? 'bg-blue-500 text-white' : 'hover:bg-blue-500 hover:text-white'
                }`}
              >
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      )}

      {selectedOption && (
        <div className="absolute top-0 right-0 bottom-0 flex items-center pr-4">
          <span className="text-blue-500">{selectedOption.label}</span>
        </div>
      )}
    </div>
  );
};

// Example usage:
// const options: Option[] = [
//   { value: '1', label: 'Option 1' },
//   { value: '2', label: 'Option 2' },
//   { value: '3', label: 'Option 3' },
// ];

// const MyComponent: React.FC = () => {
//   const handleSelect = (option: Option) => {
//     console.log('Selected option:', option);
//   };

//   return <DropdownSearch options={options} onSelect={handleSelect} defaultValue="2" />;
// };

export default DropdownSearch;
