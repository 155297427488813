import React from "react";
import "./loader.css";

type loaderProps = {
  size: "small" | "medium" | "big";
};
const Loader = ({ size }: loaderProps) => {
  let loaderStyle = {};

  switch (size) {
    case "small":
      loaderStyle = { width: "20px", height: "20px", borderWidth: "3px" };
      break;
    case "medium":
      loaderStyle = { width: "100px", height: "100px", borderWidth: "6px" };
      break;
    case "big":
      loaderStyle = { width: "150px", height: "150px", borderWidth: "8px" };
      break;
    default:
      break;
  }
  return (
    <div className="loader-container mx-2">
      <div className="loader" style={loaderStyle}></div>
    </div>
  );
};

export default Loader;
