import { useQuery } from "react-query";
import { getData, getMembersList} from "../api/api"


function useProjectsList(action:string, id: any) {
    return useQuery(["fetch-project-list", id], () => getData(action, id), {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    });
  }

  function useTeamList(action:string, id: any) {
    return useQuery(["fetch-team-list", id], () => getMembersList(action, id), {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    });
  }
  
  export { useProjectsList, useTeamList}