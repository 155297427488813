import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { getData } from "../api/api";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../components/Button/button";

type createProps = {
  closeDialog: any;
  isDialogOpen: boolean;
};

const OnBoarding = ({ closeDialog, isDialogOpen }: createProps) => {
  const [openDialog, setOpenDialog] = useState(true);
  const [isbtnLoad, setIsbtnLoad] = useState(false);
  const [errorValidation, setErrorValidation] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState<any>("");
  const [imageLoader, setImageLoader] = useState(false);
  
  const [orgDetails, setOrgDetails] = useState([]);
  const user_id = Cookies.get("id");
  const user_type = Cookies.get("user_type");
  const navigate = useNavigate();

  const [onBoard, setOnBoard] = useState<any>({
    name: "",
    teamSize: "",
    imageData: "",
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      // Create a FileReader to read the image file
      const fileName = file.name;
      setSelectedFileName(fileName);
      const reader = new FileReader();

      reader.onload = (event) => {
        if (event.target) {
          const base64Image = event.target.result;
          // Update the formData state with the Base64 image data
          setOnBoard({
            ...onBoard,
            imageData: base64Image,
          });
        }
      };

      // Read the image file as Base64
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {

    async function getOrganizationData() {
      try {
        const action = "getOrganizationDetails";
        const data = await getData(action, user_id);
        setOrgDetails(data);
        if (data.length && user_type === 'admin') {
          // Redirect to onboarding page if details are not updated
          navigate('/portal/onboarding');
        } else {
          navigate('/portal/dashboard');
        }
             // If organization details are available, populate the form fields
             if (data.length) {
              const { name, teamSize, logo } = data[0];
              setOnBoard({
                name: name || "",
                teamSize: teamSize || "",
                imageData: logo || "",
              });
            }
      } catch (error: any) {
        console.error("Error during login process:", error.message);
      }
    }
  
    getOrganizationData(); // Call the async function
  }, [navigate, user_id, user_type]);


  const handleAddOnboard = async (e: any) => {
    e.preventDefault();
    setIsbtnLoad(true);
    try {
      const response = await fetch("https://makeithost.com/api/api.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: orgDetails.length ? "onBoardUpdate" : "onBoard",
          user_id: user_id,
          companyName: onBoard.name,
          teamSize: onBoard.teamSize,
          companyLogo: onBoard.imageData,
          isSubscribed: false,
          isActive: true,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log("result", result);
      setIsbtnLoad(false);
      setErrorValidation(result.result);

      // You can add additional logic here, such as redirecting or updating the UI.
    } catch (error: any) {
      console.error("Error:", error.message);
    }
  };

 

  console.log("org details", onBoard?.imageData)
  return (
    <>
      <div className="relative p-4 w-full max-h-full">
        <div className="relative bg-white rounded shadow p-4">
          {errorValidation ? (
            <div
              className="bg-green-100 w-4/12 border border-green-400 text-green-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <strong className="font-bold">{errorValidation}</strong>
            </div>
          ) : null}
          <div className="w-4/12">
            <div className="my-2">
              <label
                htmlFor="email"
                className="block text-sm text-gray-900 w-12/12 leading-9 pe-2"
              >
                Company Name<small className="text-rose-400 mb-2">*</small>
              </label>
              <div className="mt-0 w-12/12">
                <input
                  type="text"
                  value={onBoard.name}
                  onChange={(e) =>
                    setOnBoard({ ...onBoard, name: e.target.value })
                  }
                  className="px-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="my-2">
              <label
                htmlFor="email"
                className="block text-sm text-gray-900 w-12/12 leading-9 pe-2"
              >
                Team Size<small className="text-rose-400 mb-2">*</small>
              </label>
              <div className="mt-0 w-12/12">
                <select
                  value={onBoard.teamSize}
                  onChange={(e) =>
                    setOnBoard({ ...onBoard, teamSize: e.target.value })
                  }
                  className="px-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  <option value="1-10">1-10</option>
                  <option value="10-20">10-20</option>
                  <option value="20-30">20-30</option>
                  <option value="30-50">30-50</option>
                  <option value="50+">50+</option>
                </select>
              </div>
            </div>
          </div>
          <div className="py-4">
            <p className="text-base mt-6">Logo</p>
            <div className="flex">
              {
                onBoard?.imageData ?
                <img
                className="w-14 h-14 my-2"
                src={onBoard?.imageData.toString()}
              />
              :
              <img
              className="w-14 h-14 my-2 rounded"
              src={require("../assets/defaultWorkspaceImg.png")}
            />
              }
             
              <div className="mt-4 ml-4">
                <input
                  type="file"
                  name="imageData"
                  onChange={handleFileChange}
                />
                <p className=" w-full text-xs text-slate-400 mt-1">
                  .png, .jpg, .gif files up to 8MB. Recommended size is 256 /
                  256.{" "}
                </p>
              </div>
            </div>
          </div>
          <footer className="border-t mt-9 pt-3 flex justify-end dark:border-gray-100 px-6">
            <Button
                type={"button"}
                isButtonLoaded={isbtnLoad}
                className={
                  "items-center flex justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                }
                onClick={handleAddOnboard}
                children={orgDetails.length ? "Update" : "Save"}
              />
          </footer>
        </div>
      </div>
    </>
  );
};

export { OnBoarding };
