import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchTasks, getMemberDetails } from "../api/api";
import { Button } from "../components/Button/button";
import Dropdown from "../components/DropDown";

function TaskDetails() {
  const [tasksDetails, setTasksDetails] = useState<any>([]);
  const { task_id } = useParams();

  async function fetchTasksList() {
    try {
      const action = "getTaskDetails";
      const Response = await getMemberDetails(action, task_id);
      setTasksDetails(Response);
    } catch (error: any) {
      console.error("Error during login process:", error.message);
    }
  }
  useEffect(() => {
    fetchTasksList();
  }, [task_id]);
  console.log("tasksDetails", tasksDetails);

  let statusColor;
  let icon;

  switch (tasksDetails && tasksDetails[0]?.issueType.toLowerCase()) {
    case "epic":
      statusColor = "#3498db"; // Light Blue
      icon = <i className="fas fa-rocket text-xs text-white text-base"></i>;
      break;
    case "story":
      statusColor = "#e67e22";
      icon = <i className="fas fa-book-open text-white text-base"></i>;
      break;
    case "bug":
      statusColor = "#9b59b6";
      icon = <i className="fas fa-bug text-white text-base"></i>;
      break;
    case "task":
      statusColor = "#2ecc71";
      icon = <i className="fas fa-bug text-white text-base"></i>;
      break;
    case "customerescalation":
      statusColor = "#34495e";
      icon = (
        <i className="fas fa-exclamation-triangle text-white text-base"></i>
      );
      break;
    default:
      statusColor = ""; // Default color, you can set it to a default color if needed
  }

  function handleEdit(){

  }
  const options = [
    { value: '1', label: 'Option 1' },
    { value: '2', label: 'Option 2' },
    { value: '3', label: 'Option 3' },
  ];
  const handleSelect = (option: any) => {
    console.log('Selected option:', option);
  };
  return (
    <div>
      <div className="flex items-center">
        <span
          className="rounded-md flex items-center justify-center p-1.5 mr-1.5"
          style={{ backgroundColor: statusColor }}
        >
          {icon}
        </span>
        <h1 className="text-3xl font-semibold capitalize">
          {/* {tasksDetails && tasksDetails[0]?.task_id}:  */}
          {tasksDetails && tasksDetails[0]?.task_name}
        </h1>
      </div>
    <div className="flex gap-1 w-100 my-4">
    <Button
          type={"submit"}
          className={
            "items-center flex justify-center rounded-lg bg-slate-900 hover:bg-slate-700 px-3  h-8 font-semibold  text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          }
          onClick={handleEdit}
          children={"Edit"}
        />
        <Button
          type={"submit"}
          className={
            "items-center flex justify-center rounded-lg bg-slate-900 hover:bg-slate-700 px-3  h-8 font-semibold  text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          }
          onClick={handleEdit}
          children={"Start Process"}
        />
        <Button
          type={"submit"}
          className={
            "items-center flex justify-center rounded-lg bg-slate-900 hover:bg-slate-700 px-3  h-8 font-semibold  text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          }
          onClick={handleEdit}
          children={"Resolved"}
        />
        <Dropdown options={options} onSelect={handleSelect} />

    </div>
      <p className="text-lg my-2">
        Description: {tasksDetails && tasksDetails[0]?.description}
      </p>
      <div className="flex flex-grow my-2">
        <div className="flex-none w-20">Assignee: </div>
        <div className="flex-grow">
          {tasksDetails && tasksDetails[0]?.assignee}
        </div>
      </div>
      <div className="flex flex-grow my-2">
        <div className="flex-none w-20">Type: </div>
        <div className="flex-grow">
          {tasksDetails && tasksDetails[0]?.issueType}
        </div>
      </div>
      <div className="flex flex-grow my-2">
        <div className="flex-none w-20">Type: </div>
        <div className="flex-grow">
          {tasksDetails && tasksDetails[0]?.issueType}
        </div>
      </div>
    </div>
  );
}

export { TaskDetails };
