const BASE_URL = "http://makeithost.com/api/api.php";

async function fetchTasks(action, org_id) {
  var raw = JSON.stringify({
    action: `${action}`,
    org_id: org_id
  });
  var requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: raw,
    redirect: "follow",
  };
  const response = await fetch(`${BASE_URL}`, requestOptions);

  const data = await response.json();
  return data;
}

async function getProjects(action, userID) {
    var raw = JSON.stringify({
      action: action,
      user_id: userID
    });
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(`${BASE_URL}`, requestOptions);
  
    const data = await response.json();
    return data;
  }
  async function getMembersList(action, userID) {
    var raw = JSON.stringify({
      action: action,
      user_id: userID
    });
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(`${BASE_URL}`, requestOptions);
  
    const data = await response.json();
    return data;
  }
  async function getData(action, userID) {
    var raw = JSON.stringify({
      action: action,
      user_id: userID
    });
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(`${BASE_URL}`, requestOptions);
  
    const data = await response.json();
    return data;
  }

  async function getDataDetails(action, userID, id) {
    var raw = JSON.stringify({
      action: action,
      user_id: userID,
      id: id,
    });
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(`${BASE_URL}`, requestOptions);
  
    const data = await response.json();
    return data;
  }

  async function getMemberDetails(action, id) {
    var raw = JSON.stringify({
      action: action,
      id: id,
    });
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(`${BASE_URL}`, requestOptions);
  
    const data = await response.json();
    return data;
  }
export { BASE_URL, fetchTasks, getProjects, getMembersList, getData, getDataDetails, getMemberDetails };
