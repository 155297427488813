import React from 'react';
import { createContext, useContext, useState, useEffect } from 'react';
import { BASE_URL} from '../api/api.js';
import Cookies from "js-cookie";

interface MemberDetailsContextType {
  memberDetails: any; // Set the appropriate type for memberDetails
  error: string | null;
}

const MemberDetailsContext = createContext<MemberDetailsContextType | undefined>(undefined);

export const MemberDetailsProvider = ({ children }: { children: any }) => {
  const [memberDetails, setMemberDetails] = useState(null);
  const [error, setError] = useState<any>(null);
  const user_id: any = Cookies.get("id");
  const user_type = Cookies.get("user_type");

  useEffect(() => {
    const fetchMemberDetails = async () => {
      const action = "getOrgId";
      try {
        const response = await fetch(`${BASE_URL}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ id: user_id, user_type: user_type, action }),
        });

        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        const data = await response.json();
        setMemberDetails(data);
      } catch (error: any) {
        setError(`Error fetching member details, ${error.message}`);
      }
    };

    if (user_id && (user_type === 'admin' || user_type === 'member')) {
      fetchMemberDetails();
    } else {
      setError('Invalid user ID or user type');
    }
  }, [user_id, user_type]);

  const contextValue = {
    memberDetails,
    error,
  };

  return (
    <MemberDetailsContext.Provider value={contextValue}>
      {children}
    </MemberDetailsContext.Provider>
  );
};

export const useMemberDetails = () => {
  const context = useContext(MemberDetailsContext);
  if (!context) {
    throw new Error('useMemberDetails must be used within a MemberDetailsProvider');
  }
  return context;
};
